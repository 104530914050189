<template>
  <v-card
    flat
    outlined
    class="rounded-lg"
    height="calc(100vh - 60px - 24px)"
    style="overflow: auto"
    >
    <v-card-text
      class="px-0"
      >
      <div
        class="d-flex px-4"
        >
        <v-menu
          offset-y
          >
          <template
            v-slot:activator="{ on, attrs}"
            >
            <v-btn
              depressed
              fab
              small
              color="primary"
              class="rounded-l-lg rounded-r-0"
              style="margin-right: -1px"
              v-on="on"
              v-bind="attrs"
              >
              <v-icon>
                mdi-filter-variant
              </v-icon>
            </v-btn>
          </template>

          <v-list
            style="width: 184px"
            dense
            subheader
            >
            <v-subheader class="px-5 body-2 font-weight-medium black--text">Filtros</v-subheader>

            <v-divider></v-divider>

            <v-list-item>
              <v-list-item-action
                class="mr-2"
                >
                <v-checkbox
                  dense
                  class="mt-n1"
                  v-model="filters.joined"></v-checkbox>
              </v-list-item-action>

              <v-list-item-title>Estoy inscrito</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-text-field
          outlined
          solo
          flat
          label="Buscar..."
          dense
          hide-details
          class="rounded-r-lg rounded-l-0"
          v-model="filters.search"
          ></v-text-field>
      </div>

      <div
        class="pa-1 mt-1 d-flex flex-wrap"
        style="gap: 4px"
        v-if="filters.search || filters.joined"
        >
        <template
          v-for="attr in Object.keys(filters)"
          >
          <v-chip
            small
            v-if="filters[attr]"
            close
            @click:close="filters[attr] = null"
            >
            {{ attr == 'search' ? filters[attr] : translate[attr] }}
          </v-chip>
        </template>
      </div>

      <div
        class="pa-1 px-4 black--text mt-3"
        >
        Listado de ferias
      </div>

      <v-list
        three-line
        dense
        >
        <template
          v-for="fair in fairs"
          >
          <v-list-item
            @click="selected = fair.id"
            :class="selected == fair.id ? 'primary white--text' : ''"
            >
            <v-list-item-avatar
              :color="selected == fair.id ? 'white' : 'primary'"
              >
              <v-img 
                v-if="fair.image"
                :src="url + fair.image.url"></v-img>

              <v-icon
                v-else
                :color="selected == fair.id ? 'primary' : 'white'"
                >
                mdi-string-lights
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="fair.name"></v-list-item-title>
              <v-list-item-subtitle
                :class="selected == fair.id ? 'primary white--text' : ''"
                >{{ (new Date(fair.startsAt)).toLocaleDateString() }} - {{ (new Date(fair.endsAt)).toLocaleDateString() }}</v-list-item-subtitle>
              <v-list-item-subtitle
                :class="selected == fair.id ? 'primary white--text' : ''"
                >{{ fair.storeIds.includes(currentUser.selectedProfile.profilableId) ? 'Inscrito' : 'Sin inscribir' }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { PublicFairs } from '@/graphql/queries/fairs/fairs'

export default {
  data: () => ({
    fairs: null,
    selected: null,
    filters: {
      search: null,
      joined: null,
    },
    translate: {
      joined: 'Estoy inscrito'
    },
    timer: null
  }),

  created () {
    this.fetchFairs()
  },

  watch: {
    selected (id) {
      this.$emit('select', id)
    },

    filters: {
      deep: true,
      
      handler () {
        if (this.timer)
          clearTimeout(this.timer)

        this.timer = setTimeout( v => {
          this.fetchFairs()
        }, 500)
      }
    }
  },

  computed: {
    ...mapGetters(['url', 'currentUser'])
  },

  methods: {
    fetchFairs () {
      this.$apollo.query({
        query: PublicFairs,
        variables: {
          ...this.filters
        },
        fetchPolicy: 'no-cache'
      }).then( res => {
        this.fairs = res.data.publicFairs
      })
    }
  }
}
</script>
